<template>
  <article
    class="restriction-modal"
    data-t="restriction-modal"
    :class="{ mobile: isMobile }"
  >
    <button
      v-if="isMobile"
      class="close-button"
      data-t="close-button-restriction-modal-36up"
      @click="close"
    >
      <StIcon name="cross-large" :size="20" />
    </button>
    <figure>
      <img src="./location.png" alt="location" class="location" />
      <figcaption class="header">
        {{ t('restriction.accessDeniedBetone') }}
      </figcaption>
    </figure>
    <div class="ip-info">
      <address class="country">
        <StIcon class="bottom-icon" size="16" :name="countryIcon" />
        {{ countryName }}
      </address>
      <div class="ip">
        <StIcon class="bottom-icon-pin" size="20" name="location-pin-solid" />
        {{ ip }}
      </div>
    </div>
    <h3 class="second-header">{{ t('restriction.howGetAccess') }}</h3>
    <I18nT class="info" keypath="restriction.vpn" tag="p">
      <template #countries>
        <b>
          <span class="countries">{{ t('restriction.countries') }}</span>
        </b>
      </template>
    </I18nT>
    <div class="boxes">
      <a
        v-for="box in boxes"
        :id="box.id"
        :key="box.title"
        class="box"
        type="text-secondary"
        :href="box.link"
        :target="box.icon === 'book-solid' ? '_blank' : '_self'"
      >
        <StIcon class="box-icon" :name="box.icon" size="20" />
        <h4 class="box-title">{{ box.title }}</h4>
      </a>
    </div>
    <StButton
      class="button"
      type="primary"
      :label="t('restriction.goBack')"
      size="l"
      @click="close"
    />
  </article>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import { useIntercomApi } from '@st/intercom/composables/useIntercomApi'
import { useCountryName } from './useCountryName'

const { t } = useI18n()
const { sendEvent } = useAnalytics()

const props = defineProps<{
  ip: string
  countryCode: string
}>()

sendEvent('geoUnavailable')

const router = useRouter()
const route = useRoute()
function close() {
  router.push({ query: { ...route.query, modal: undefined } })
}
const { toogleChat } = useIntercomApi()
const { supportEmailLink, createSupportLink } = useSupportUrl()

const boxes = computed(() => [
  {
    icon: 'book-solid',
    title: t('restriction.reference'),
    link: createSupportLink('/articles/9516574'),
  },
  {
    icon: 'support-solid',
    title: t('restriction.live'),
    id: 'live-chat',
    buttonHandler: toogleChat,
  },
  {
    icon: 'mail-solid',
    title: t('restriction.email'),
    link: supportEmailLink,
  },
])

const { isMobile } = usePlatform()

const { ip, countryCode } = toRefs(props)

const lowercaseCountryCode = computed(() => countryCode.value?.toLowerCase())

const countryName = useCountryName(lowercaseCountryCode)
const countryIcon = computed(
  () => `f-${lowercaseCountryCode.value}` as IconName,
)
</script>

<style scoped>
.restriction-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 500px;
  padding: var(--spacing-050) var(--spacing-500) var(--spacing-500);

  text-align: center;

  background: var(--background-base);
  border-radius: var(--border-radius-150);

  * {
    margin: 0;
  }
}

.header {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
}

.second-header {
  margin-bottom: var(--spacing-050);
  font: var(--desktop-text-md-semibold);
}

.info {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.countries {
  color: var(--text-primary);
}

.button {
  width: 100%;
  font: var(--desktop-text-sm-semibold);
}

.ip-info {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-500);
}

.country,
.ip {
  display: flex;
  align-items: center;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.country {
  gap: var(--spacing-050);
  padding-right: var(--spacing-200);
  border-right: 1px solid var(--border-primary);
}

.ip {
  gap: var(--spacing-050);
  margin-left: var(--spacing-200);
}

.bottom-icon {
  margin-right: var(--spacing-075);
}

.location {
  width: 314px;
  margin-top: var(--spacing-200);
  margin-bottom: var(--spacing-100);
}

.close-button {
  all: unset;

  position: absolute;
  top: 24px;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}

.boxes {
  display: flex;
  gap: var(--spacing-100);
  justify-content: center;
  margin-bottom: var(--spacing-500);
}

.box {
  all: unset;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 128px;
  padding: var(--spacing-150) var(--spacing-150) var(--spacing-125)
    var(--spacing-150);

  background: none;
  border: var(--border-width-medium) solid var(--border-secondary);
  border-radius: var(--border-radius-100);

  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: var(--background-secondary);
  }
}

.box-icon,
.bottom-icon-pin {
  background: var(--icon-secondary);
}

.box-title {
  font: var(--desktop-text-sm-medium);
}

.restriction-modal.mobile {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-200) var(--spacing-200) var(--spacing-200);

  .location {
    width: 263px;
    margin-bottom: 0;
  }

  .header {
    margin-bottom: var(--spacing-100);
    font: var(--mobile-title-1-semibold);
  }

  .ip-info {
    margin-bottom: var(--spacing-300);
    font: var(--mobile-caption-1-regular);
  }

  .second-header {
    margin-bottom: var(--spacing-075);
    font: var(--mobile-headline-semibold);
  }

  .info {
    margin-bottom: var(--spacing-100);
    font: var(--mobile-text-content-regular);
  }

  .box-title {
    font: var(--mobile-text-content-regular);
  }
}
</style>
